import { useEffect, useState } from 'react'
import { type IUseDetailConsultation } from '../interfaces/IUseDetailConsultation'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../redux/hooks/StoreHook'
import { useParams, useLocation } from 'react-router-dom'
import useChatbotService from '../../../../../services/user/useChatbotService'
import {
  clearActiveChat,
  setActiveChat,
} from '../../../../../../../redux/features/chatbot/active-chat/ActiveChatSlice'
import { ChatbotMessageRols } from '../../../../../../../core/components/chatbot/message/enum/ChatbotMessageTypes'
import {
  addNewChatToHistory,
  updateChatInHistory,
} from '../../../../../../../redux/features/chatbot/chats-history/ChatsHistorySlice'
import {
  type IChatbotMessageMetadata,
  type ISearchProcess,
  type ISource,
  type ICompanySource,
  type IPublicSource,
  isCompanySource,
} from '../../../../../../../core/components/chatbot/message/interfaces/IChatbotMessage'
import { useTranslation } from 'react-i18next'
import { type IChatHistoryItem } from '../../../../../../../redux/features/chatbot/chats-history/IChatsHistory'
import { detailConsultationLastQsObservable$ } from '../../../../../observables/suggested-questions-last-message/detail-consultation/detailConsultationLastQsObservable'
import useScrollPanelToBottom from '../../../../../../../core/hooks/useScrollElToBottom'

export function mergeMetadata(
  existingMetadata: IChatbotMessageMetadata,
  newMetadata: IChatbotMessageMetadata,
): IChatbotMessageMetadata {
  return {
    sources: [
      ...(existingMetadata.sources ?? []),
      ...(newMetadata.sources ?? []),
    ],
    firstSearch: mergeSearchProcesses(
      existingMetadata.firstSearch,
      newMetadata.firstSearch,
    ),
    secondSearch: mergeSearchProcesses(
      existingMetadata.secondSearch,
      newMetadata.secondSearch,
    ),
    allResultsCount:
      newMetadata.allResultsCount ?? existingMetadata.allResultsCount,
  }
}

function mergeSearchProcesses(
  existingProcesses?: Record<number, ISearchProcess>,
  newProcesses?: Record<number, ISearchProcess>,
): Record<number, ISearchProcess> | undefined {
  if (existingProcesses && newProcesses) {
    const mergedProcesses: Record<number, ISearchProcess> = {
      ...existingProcesses,
    }

    Object.keys(newProcesses).forEach((id) => {
      const numericId = Number(id)
      if (isNaN(numericId)) {
        // Optionally handle invalid IDs
        console.warn(`Invalid process ID: ${id}`)
        return
      }

      const existingProcess = existingProcesses[numericId] || {
        id: numericId,
        results: [],
      }
      const newProcess = newProcesses[numericId]

      mergedProcesses[numericId] = mergeSearchProcess(
        existingProcess,
        newProcess,
      )
    })

    return mergedProcesses
  }

  return existingProcesses ?? newProcesses
}

function mergeSearchProcess(
  existingProcess: ISearchProcess,
  newProcess: ISearchProcess,
): ISearchProcess {
  return {
    id: existingProcess.id || newProcess.id,
    searchQuery: newProcess.searchQuery || existingProcess.searchQuery,
    queryReason: newProcess.queryReason || existingProcess.queryReason,
    isPrivate:
      newProcess.isPrivate !== undefined
        ? newProcess.isPrivate
        : existingProcess.isPrivate,
    results:
      newProcess.results && newProcess.results.length > 0
        ? newProcess.results
        : existingProcess.results,
  }
}

export function mapResponseToMetadata(response: any): IChatbotMessageMetadata {
  const metadata: IChatbotMessageMetadata = {}

  // Map first search
  if (response['1st_search']) {
    metadata.firstSearch = mapSearchProcess(response['1st_search'])
  }

  // Map second search
  if (response['2nd_search']) {
    metadata.secondSearch = mapSearchProcess(response['2nd_search'])
  }

  // Map first search
  if (response.first_search) {
    metadata.firstSearch = mapSearchProcess(response.first_search)
  }

  // Map second search
  if (response.second_search) {
    metadata.secondSearch = mapSearchProcess(response.second_search)
  }

  // Map citations to sources
  if (response.citations) {
    metadata.sources = response.citations.map(mapSource)
  }

  // Map citations to sources
  if (response.sources) {
    metadata.sources = response.sources.map(mapSource)
  }

  // Map all results count
  if (
    response.selection &&
    typeof response.selection.all_results_count === 'number'
  ) {
    metadata.allResultsCount = response.selection.all_results_count
  }

  return metadata
}

function mapSearchProcess(searchData: any): Record<number, ISearchProcess> {
  const processes: Record<number, ISearchProcess> = {}

  Object.keys(searchData).forEach((key) => {
    const queryData = searchData[key]
    const id = parseInt(key.replace('query_', ''), 10) // Extract the ID from 'query_#'

    // Initialize or update the search process
    const existingProcess = processes[id] || {
      id,
      searchQuery: '',
      queryReason: '',
      isPrivate: false,
      results: [],
    }

    // Update the existing process with new data
    if (queryData.search_query) {
      existingProcess.searchQuery = queryData.search_query
    }
    if (queryData.query_reason) {
      existingProcess.queryReason = queryData.query_reason
    }
    if (Object.prototype.hasOwnProperty.call(queryData, 'is_private')) {
      // Updated line
      existingProcess.isPrivate = queryData.is_private
    }
    if (Array.isArray(queryData.results)) {
      existingProcess.results = queryData.results.map(mapSource)
    }

    processes[id] = existingProcess
  })

  return processes
}

function mapSource(sourceData: any): ISource {
  if (isCompanySource(sourceData)) {
    const source: ICompanySource = {
      position: sourceData.position || 0,
      score: sourceData.score || 0,
      rerank: sourceData.rerank || 0,
      captions: sourceData.captions || '',
      id: sourceData.id || '',
      title: sourceData.title || '',
      category: sourceData.category || '',
      tags: sourceData.tags || [],
      uploaded_by_id: sourceData.uploaded_by_id || '',
      page: sourceData.page || 0,
      year: sourceData.year || 0,
      file_path: sourceData.file_path || '',
      external_id: sourceData.external_id || '',
      content: sourceData.content || '',
      fragment_number: sourceData.fragment_number || 0,
    }
    return source
  } else {
    const source: IPublicSource = {
      position: sourceData.position || 0,
      score: sourceData.score || 0,
      rerank: sourceData.rerank || 0,
      captions: sourceData.captions || '',
      id: sourceData.id || '',
      title: sourceData.title || '',
      author: sourceData.author || '',
      keywords: sourceData.keywords || '',
      category: sourceData.category || '',
      page: sourceData.page || 0,
      year: sourceData.year || 0,
      has_copyright: sourceData.has_copyright || false,
      file_path: sourceData.file_path || '',
      external_id: sourceData.external_id || '',
      content: sourceData.content || '',
      fragment_number: sourceData.fragment_number || 0,
    }
    return source
  }
}

const useDetailConsultation = (): IUseDetailConsultation => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [suggestedQuestionsLastMessage, setSuggestedQuestionsLastMessage] =
    useState<string[]>([])
  const chatsHistoryState = useAppSelector((state) => state.chatsHistory)
  const activeChatState = useAppSelector((state) => state.activeChat)
  const chatSettingsState = useAppSelector((state) => state.chatSettings)
  const detailConsultationLastQsObserver$ =
    detailConsultationLastQsObservable$.getSubject()
  const { getMessages, getChatSettings } = useChatbotService()
  const { referenceIdentifier } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  useScrollPanelToBottom({
    elSelector: '.panel__mainContainer',
    delay: 500,
    dependencies: [activeChatState],
  })

  useEffect(() => {
    const subscriberSuggestedQuestions =
      detailConsultationLastQsObserver$.subscribe((data: string[]) => {
        setSuggestedQuestionsLastMessage(data)
      })

    return () => {
      subscriberSuggestedQuestions.unsubscribe()
    }
  }, [])

  useEffect(() => {
    return () => {
      setIsLoading(true)
      dispatch(clearActiveChat())
    }
  }, [referenceIdentifier])

  const location = useLocation()
  const isTemporary = location.pathname.includes('/temporary/')

  useEffect(() => {
    if (referenceIdentifier) {
      if (referenceIdentifier === 'loading') {
        setIsLoading(false)
      } else {
        const chatFoundInChatsHistory = chatsHistoryState.find(
          (item) => item.uuid === referenceIdentifier,
        )

        if (
          chatFoundInChatsHistory?.isReviewed &&
          chatFoundInChatsHistory?.chat_settings
        ) {
          dispatch(
            setActiveChat({
              ...chatFoundInChatsHistory,
            }),
          )
          setIsLoading(false)
        } else {
          // Handle temporary chats
          if (isTemporary && !activeChatState.chat_settings) {
            const loadTemporaryChat = async (): Promise<void> => {
              try {
                const settings = await getChatSettings()
                const chatSetting: any = settings.find(
                  (setting: { uuid: string }) =>
                    setting.uuid === referenceIdentifier,
                )

                if (!chatSetting) {
                  console.error('Chat setting not found')
                }

                const newInfoForChat: IChatHistoryItem = {
                  uuid: chatSetting.uuid,
                  resource_id: null,
                  chat_settings: {
                    uuid: chatSetting.uuid,
                    title: chatSetting.title,
                    default_filters: chatSetting.default_filters,
                    description: chatSetting.description,
                    image: chatSetting.image,
                    color: chatSetting.color,
                    suggested_questions: chatSetting.suggested_questions,
                  },
                  name: chatSetting.title,
                  messages: [],
                  suggested_questions: chatSetting.suggested_questions,
                  isReviewed: true,
                  isLoadingResponse: false,
                  isInPagination: !!chatFoundInChatsHistory,
                  isTemporary: true,
                }
                dispatch(addNewChatToHistory(newInfoForChat))
                dispatch(setActiveChat(newInfoForChat))
              } catch (err) {
                console.error('Error getting chat settings:', err)
              } finally {
                setIsLoading(false)
              }
            }

            void loadTemporaryChat()
          }

          void getMessages({ payload: { uuid: referenceIdentifier } })
            .then((res: IChatHistoryItem | any) => {
              if (res.uuid && res.messages && res.name && res.chat_setting) {
                const chatUuid = res.uuid
                const messagesResponse = res.messages.map((item: any) => {
                  if (item.type === ChatbotMessageRols.USER) {
                    return {
                      ...item,
                      uuidChatOrigin: chatUuid,
                    }
                  } else {
                    const metadata: IChatbotMessageMetadata =
                      mapResponseToMetadata(item.metadata)
                    return {
                      ...item,
                      uuidChatOrigin: chatUuid,
                      metadata,
                      prompt: item.prompt,
                      suggestedQuestions: [],
                      isTypingCompleted: true,
                    }
                  }
                })

                const newInfoForChat: IChatHistoryItem = {
                  uuid: res.uuid,
                  resource_id: res.resource_id,
                  chat_settings: {
                    uuid: res.chat_setting.uuid,
                    title: res.chat_setting.title,
                    default_filters: res.chat_setting.default_filters,
                    description: res.chat_setting.description,
                    image: res.chat_setting.image,
                    color: res.chat_setting.color,
                    suggested_questions: res.suggested_questions,
                  },
                  name:
                    chatFoundInChatsHistory?.name ===
                    t('CHATBOT.NEW_CONSULTATION')
                      ? chatFoundInChatsHistory.name
                      : res.name,
                  messages: messagesResponse,
                  suggested_questions:
                    messagesResponse.length === 0
                      ? res.chat_setting.suggested_questions
                      : [],
                  isReviewed: true,
                  isLoadingResponse: false,
                  isInPagination: !!chatFoundInChatsHistory,
                  isTemporary: false, // TODO
                }

                dispatch(setActiveChat(newInfoForChat))

                const shouldAddNewChat =
                  window.outerWidth <= 979 || chatsHistoryState.length > 0
                if (chatFoundInChatsHistory) {
                  dispatch(
                    updateChatInHistory({
                      uuid: referenceIdentifier,
                      newInfo: newInfoForChat,
                    }),
                  )
                } else if (shouldAddNewChat) {
                  dispatch(addNewChatToHistory(newInfoForChat))
                }
              }
            })
            .catch((err: any) => {
              console.log(err)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      }
    }
  }, [referenceIdentifier, chatsHistoryState])

  return {
    isLoading,
    suggestedQuestionsLastMessage,
    activeChatState,
    chatSettingsState,
    dispatch,
  }
}

export default useDetailConsultation
