import { createSlice } from '@reduxjs/toolkit'
import { type IChatsHistory } from './IChatsHistory'
import { chatsHistoryReducers } from '../../../reducers/chatbot/chats-history/ChatsHistoryReducers'

export const initialState: IChatsHistory = []

const chatsHistorySlice = createSlice({
  initialState,
  name: 'chatsHistory',
  reducers: chatsHistoryReducers,
})

export default chatsHistorySlice.reducer
export const {
  refreshChatsHistory,
  addNewChatToHistory,
  updateChatInHistory,
  addMessageInChatHistory,
  updateMessageInChatHistory,
  deleteChatInHistory,
  setIsLoadingResponse,
} = chatsHistorySlice.actions
