export const ES_TRANSLATIONS = {
  ES: 'Español',
  DASHBOARD: {
    EMPTY_STATE: {
      ROOT: {
        DEMO_COMPLETED: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        DEMO: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        PAYMENT_DECLINED: {
          TITLE: 'El pago de tu renovación no pudo ser procesado',
          DESCRIPTION:
            'Reactiva tu cuenta completando el pago de tu plan a través del siguiente link.',
          CTA: 'Pagar ahora',
        },
      },
      ADMIN: {
        DEMO_COMPLETED: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        DEMO: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Compra algunos de nuestros planes y sigue disfrutando de Ariel:',
          CTA: 'Ver planes',
        },
        PAYMENT_DECLINED: {
          TITLE: 'El pago de tu renovación no pudo ser procesado',
          DESCRIPTION:
            'Reactiva tu cuenta completando el pago de tu plan a través del siguiente link.',
          CTA: 'Pagar ahora',
        },
      },
      USER: {
        DEMO_COMPLETED: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Comunícate con el propietario de la organización para adquirir alguno de nuestros planes y seguir disfrutando de Ariel.',
        },
        DEMO: {
          TITLE: 'Tiempo de prueba gratis terminado',
          DESCRIPTION:
            'Comunícate con el propietario de la organización para adquirir alguno de nuestros planes y seguir disfrutando de Ariel.',
        },
        PAYMENT_DECLINED: {
          TITLE: 'El pago de tu renovación no pudo ser procesado',
          DESCRIPTION:
            'Reactiva tu cuenta completando el pago de tu plan a través del siguiente link.',
          CTA: 'Pagar ahora',
        },
      },
      CTAS: {
        CHILD: 'Ver planes',
      },
    },
  },

  NAVBAR: {
    MENU_CHIPS: {
      HOME: 'Inicio',
      LIBRARY: 'Biblioteca',
      MY_DOCUMENTS: 'Mis documentos',
      TRANSCRIPTIONS: 'Transcripciones',
      BURGER_MENU: 'Menú',
      NEW_CONSULTATION: 'Nueva consulta',
      CONSULTATIONS: 'Consultar',
    },
    CONVERSATIONS: 'Consultas',
    MENU: 'Menú',
  },
  UPGRADE_CARD: {
    TITLE: 'Prueba gratis',
    DAYS: 'días',
    DAY: 'día',
    DESCRIPTION: {
      ACTIVE:
        'El tiempo de acceso gratis terminará dentro de <days>{{days}}</days>.',
      INACTIVE: 'Compra un plan y disfruta Ariel sin límite de tiempo',
    },
    CTA: {
      ACTIVE: 'Comprar plan',
      INACTIVE: 'Ver planes',
    },
  },
  TIME: {
    MINUTES: 'minutos',
    HOURS: 'horas',
  },
  TRANSCRIPTIONS: {
    TITLE: 'Transcripciones',
    CTA: 'Subir',
    DATA_ZERO: {
      TITLE: 'Transcribe a texto tu primer audio',
      DESCRIPTION:
        'Sube un archivo de audio para transcribirlo a texto. Luego podrás hacerle consultas a Ariel acerca de ese audio.',
      CTA: 'Subir audio',
    },
    UPLOAD_AUDIO: {
      TITLE: 'Transcribir audio',
      DESCRIPTION: 'Arrastra el archivo aquí',
      VALID_EXTENSIONS: 'Archivo en formato MP3',
      // VALID_EXTENSIONS: "Formato MP3, MP4, WAV de máximo 5MB.",
      FIELD: {
        TITLE: {
          AUDIO_FILE: 'Archivo de audio',
          TRANSCRIPTION_NAME: 'Nombre de la transcripción',
        },
      },
      CTAS: {
        SELECT: 'Seleccionar archivo',
        TRANSCRIBE: 'Transcribir',
      },
      MESSAGES: {
        REMAINING_TIME:
          'A tu organización le quedan <time>{{time}}</time> de transcripciones este mes. ¿Necesitas más? <linkToUpgrade>Aumentar horas</linkToUpgrade>',
        TIME_EXCEEDED:
          'Este audio excede las horas de transcripción disponibles este mes en tu organización.',
      },
    },
    DETAIL: {
      TYPE: {
        TRANSCRIPTION: 'Transcripción',
        CHAT: 'Chat',
      },
      NOT_FOUND: 'Transcripción no encontrada',
      SUGGESTED_QUESTIONS: {
        TITLE: 'Sugerencias',
        GENERAL_SUMMARY: 'Dame un resumen de la transcripción',
        FIVE_POINTS_SUMMARY:
          'Dame los 5 puntos más importantes de la transcripción',
      },
    },
  },
  EMAIL_VALIDATION: {
    VALIDATE_EMAIL: {
      TITLE: 'Revisa tu bandeja de entrada',
      DESCRIPTION:
        'Hemos enviado un correo de verificación a {{email}}, entra al link para verificar tu email. En caso de no encontrar el correo revisa tus correos no deseados.',
    },
    EMAIL_VERIFIED: {
      TITLE: 'Email verificado',
      DESCRIPTION:
        'Gracias por verificar tu email. Ahora empecemos completando tu perfil.',
      CTA: 'Empezar',
    },
    VALIDATION_FAILED: {
      TITLE: 'Hubo un problema con la verificación',
      DESCRIPTION:
        'Vuelve a entrar al link del correo de verificación. Si el problema persisten, ponte en contacto con soporte.',
    },
  },
  REGISTRATION: {
    CREATE_PROFILE: {
      TITLE: 'Crea tu perfil',
    },
    CREATE_COMPANY: {
      TITLE: 'Crea una nueva organización',
      DESCRIPTION:
        'Empieza con una nueva organización e invita a tus compañeros.',
      INITIAL_VALUE: 'Organización de {{name}}',
    },
    UPLOAD_IMAGE: {
      UPLOAD: 'Subir imagen',
      CHANGE: 'Cambiar imagen',
      REMOVE: 'Quitar',
    },
    PRICING: {
      TITLE: 'Escoge un plan a probar por 3 días gratis',
      DESCRIPTION:
        'Inicia tu prueba gratis con algunos de nuestro planes. Podrás cambiar de plan en cualquier momento.',
      PAYMENT: {
        MONTHLY: 'Pago mensual',
        YEARLY: 'Pago anual',
      },
      PROMO: 'Lleva 2 meses GRATIS pagando anual',
      TIERS: {
        NO_CARD_NEEDED: 'Sin tarjeta de crédito',
        PRICE_PER_MEMBER: '$<comp>{{price}}</comp> por usuario',
        CURRENCY: 'USD por mes',
        BASE_PRICE: 'Pago anual de $<comp>{{price}}</comp> USD',
        CTAS: {
          FREE_TRIAL: 'Probar GRATIS 3 días',
          CONTACT: 'Contactar Ventas',
        },
        COMPANY: {
          TITLE: 'Organización',
          SUBTITLE: '6 o más usuarios',
          DESCRIPTION:
            'Para equipos de más de 6 personas, contacta nuestro equipo de ventas para recibir un plan a la medida.',
          YEARLY: {
            UNIT_PRICE: 'A la medida',
            BASE_PRICE: 'Contacta nuestro equipo de ventas',
          },
          MONTHLY: {
            UNIT_PRICE: 'A la medida',
            BASE_PRICE: 'Contacta nuestro equipo de ventas',
          },
        },
      },
    },
    INVITE: {
      TITLE: 'Invita a los miembros de tu organización',
      DESCRIPTION:
        'Los miembros recibirán un correo de invitación con el que podrán crear su cuenta asociada a tu organización.',
    },
    COMPLETED: {
      TITLE: '¡Ya está todo listo!',
      DESCRIPTION:
        'Tendrás acceso a Ariel hasta el día <date>{{date}}<date/>. En cualquier momento podrás comprar un plan para extender tu acceso y el de los miembros de tu organización.',
    },
    PLANS: {
      PERSONAL: 'Personal: 1 usuario',
      TEAM3: 'Trío: 3 usuarios',
      TEAM5: 'Equipo: 5 usuarios',
      COMPANY: 'Organización: 6 o más usuarios',
    },
  },
  MEMBERS_LIST: {
    OWNER: 'Propietario',
    USER: 'Miembro',
    ADMIN: 'Admin',
  },
  FORMS: {
    LABELS: {
      NAMES: 'Nombres',
      LAST_NAME: 'Apellidos',
      COMPANY: 'Organización',
      PLAN: 'Plan',
      MEMBERS: 'Miembros',
      PHONE: 'Numero de teléfono',
    },
    PLACEHOLDERS: {
      NAMES: 'Nombres',
      NAME: 'Nombre',
      EMAIL: 'Email',
      LAST_NAME: 'Apellidos',
      PHONE: 'Teléfono',
    },
    BLUR: {
      INVALID_EMAIL: 'Email inválido',
    },
    CTAS: {
      NEXT: 'Siguiente',
      START: 'Empezar',
    },
  },
  CHATBOT: {
    CONSULTATIONS: 'Consultas',
    NEW_CONSULTATION: 'Nueva consulta',
    CREATE_CONSULTATION: {
      GREET: '¡Hola {{userName}}!',
      DESCRIPTION: '¿Qué tipo de consulta quieres hacer?',
    },
    SUGGESTIONS: 'Sugerencias',
    SOURCES_LABEL: 'Citas',
    SOURCES_LABEL_2: 'Fuentes',
    SEE_ALL: 'Ver fuentes',
    DETAIL: {
      SOURCE_NUMBER: 'Fuente # {{numberOfSource}}',
      SOURCES_CONSULTED: 'Fuentes consultadas',
    },
    LOADING: {
      GENERATING_INITIAL_SEARCH: 'Generando búsqueda inicial',
      IMPROVING_SEARCH: 'Mejorando la búsqueda',
      ANALYZING_DOCUMENTS: 'Analizando los documentos',
      GENERATING_RESPONSE: 'Generando respuesta',
    },
    CLAUSE:
      'Las respuestas de Ariel no constituyen una recomendación legal ni remplazan una asesoría.',
    PLACEHOLDER: 'Escribe tu mensaje...',
    MESSAGE: {
      ERROR: {
        TITLE: 'Hubo un error en la solicitud.',
        DESCRIPTION: 'Error {{code}}: {{message}}',
        UNKNOWN: 'desconocido',
        UNABLE_CONNECTION: 'Unable to connect to the server',
      },
    },
    SOURCES: {
      TITLE_AND_PAGE: '{{title}} - Página {{page}}',
      TITLE: 'Título',
      PAGE: 'Página',
    },
  },
  TOOLTIP: {
    COPY: 'Copiar',
    DOWNLOAD: 'Descargar',
    PAGE: 'Página {{page}}',
  },
  MESSAGES: {
    ASK_ABOUT_TRANSCRIPTION:
      'Hazle preguntas a Ariel acerca de esta transcripción.',
  },
  BTN: {
    OPEN: 'Abrir',
  },
  ASIDE: {
    TITLE: {
      SOURCES_CONSULTED: 'Fuentes consultadas',
    },
    REFERENCED_FRAGMENT: 'Fragmento referenciado',
    PAGE_NUMBER: 'Página {{numberOfPage}}',
    LOADING: 'Cargando Fuente ',
    CTAS: {
      OPEN: 'Descargar PDF',
    },
  },
  TOAST: {
    SEE: 'Ver',
    RETRY: 'Reintentar',
    TEXT_COPIED: 'Texto copiado',
  },
  MODAL: {
    TITLE: {
      GENERAL_CONVERSATION: 'Nombre de la consulta',
      TRANSCRIPTION_CONVERSATION: 'Nombre de la transcripción',
      MENU: 'Menú',
    },
    CTAS: {
      CANCEL: 'Cancelar',
      SAVE: 'Guardar',
    },
  },
  ALERT: {
    TITLE: {
      DELETE_CONVERSATION: 'Eliminar consulta',
      DELETE_TRANSCRIPTION: 'Eliminar transcripción',
    },
    DESCRIPTION: {
      DELETE_CONVERSATION:
        '¿Estás seguro de eliminar <1>"{{conversationName}}"</1>? Esta acción no se podrá revertir.',
    },
    CTAS: {
      CANCEL: 'Cancelar',
      DELETE: 'Eliminar',
    },
  },
  TABLE: {
    NAME: 'NOMBRE',
    DATE: 'SUBIDO EL',
    STATUS: {
      PROCESSING: 'Procesando',
      PENDING: 'Pendiente',
      ERROR: 'Error',
    },
  },
  DROPDOWN: {
    CONVERSATION: {
      EDIT_NAME: 'Editar nombre',
      DELETE: 'Eliminar consulta',
    },
    TRANSCRIPTION: {
      EDIT_NAME: 'Editar nombre',
      DELETE: 'Eliminar transcripción',
    },
    ROLS: {
      MEMBER: 'Miembro',
      ADMIN: 'Admin',
    },
  },
  TABS: {
    TRANSCRIPTION: 'Transcripción',
    QUERIES: 'Chat',
  },
  ERRORS: {
    ERROR_OCCURRED: 'Ocurrió un error',
    ERROR_SERVER: 'Error de servidor',
    INVALID_EMAIL: 'Email no válido',
    INCORRECT_PHONE_NUMBER: 'Número de teléfono no válido',
    SIZE_EXCEEDED:
      'El archivo supera las 25 MB. Prueba con un audio más ligero.',
    AUDIO_FORMAT:
      'Formato de archivo no soportado. Intenta con otro archivo de audio.',
    IMAGE_FORMAT:
      'Formato de archivo no soportado. Intenta con otro archivo de imagen.',
    EMPTY_FIELDS: 'Algunos campos están vacíos',
  },
  FOOTER: {
    SETTINGS: 'Ajustes',
    SUPPORT: 'Contactar soporte',
    SUGGESTIONS: 'Dejar una sugerencia',
    TERMS_AND_CONDITIONS: 'Términos & Condiciones',
    PRIVICY_POLICIES: 'Políticas de privacidad',
    CLOSE_SESSION: 'Cerrar sesión',
    COPYRIGHT: '©2024 Ariel',
  },
}
